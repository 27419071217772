import * as actionTypes from 'store/actions/actionTypes';
import { selectTab } from 'store/actions/index';

const drawingModeEnable = (enable) => ({
  type: actionTypes.ENABLE_DRAWING_MODE,
  enable,
});

const boundarySelect = (geoJson) => ({
  type: actionTypes.BOUNDARY_SELECT,
  geoJson,
});

const layerToggle = (layerId) => ({
  type: actionTypes.TOGGLE_MAP_LAYER,
  layerId,
});

const mapClear = () => ({
  type: actionTypes.CLEAR_MAP,
});

const viewPortSet = (boundingBox) => ({
  type: actionTypes.SET_VIEWPORT,
  boundingBox,
});

const leftSplitLayerSet = (layerId) => ({
  type: actionTypes.SET_LEFT_SPLIT_LAYER,
  layerId,
});

const rightSplitLayerSet = (layerId) => ({
  type: actionTypes.SET_RIGHT_SPLIT_LAYER,
  layerId,
});

// Add batch analysis boundary
export const batchBoundaryAdd = (geoJson, boundaryId) => ({
  type: actionTypes.ADD_BATCH_BOUNDARY,
  geoJson,
  boundaryId,
});

// remove batch analysis boundary
export const batchBoundaryRemove = (boundaryId) => ({
  type: actionTypes.REMOVE_BATCH_BOUNDARY,
  boundaryId,
});

// reset batch analysis boundary
export const batchBoundariesReset = () => ({
  type: actionTypes.RESET_BATCH_BOUNDARIES,
});


export const enableDrawingMode = (enable) => (dispatch) => {
  dispatch(drawingModeEnable(enable));
};

export const selectBoundary = (geoJson, isStatisticsHidden) => (dispatch) => {
  dispatch(boundarySelect(geoJson));
  if (!isStatisticsHidden) {
    dispatch(selectTab('Statistics'));
  }
};

export const toggleLayer = (layerId) => (dispatch) => {
  dispatch(layerToggle(layerId));
};

export const setLeftSplitLayer = (layerId) => (dispatch) => {
  dispatch(leftSplitLayerSet(layerId));
};

export const setRightSplitLayer = (layerId) => (dispatch) => {
  dispatch(rightSplitLayerSet(layerId));
};

export const addBatchBoundary = (geoJson, boundaryId, isStatisticsHidden) => (dispatch) => {
  dispatch(batchBoundaryAdd(geoJson, boundaryId));
  if (!isStatisticsHidden) {
    dispatch(selectTab('Statistics'));
  }
}

export const removeBatchBoundary = (boundaryId) => (dispatch) => {
  dispatch(batchBoundaryRemove(boundaryId));
}

export const resetBatchBoundaries = () => (dispatch) => {
  dispatch(batchBoundariesReset());
}

export const clearMap = () => (dispatch) => {
  dispatch(mapClear());
};

export const setViewPort = (placeId) => (dispatch) => {
  dispatch(viewPortSet(placeId));
};

const layerClassToggle = (layerId, classId, isVisible) => ({
  type: actionTypes.TOGGLE_LAYER_CLASS,
  layerId,
  classId,
  isVisible,
});

export const toggleLayerClass = (layerId, classId, isVisible) => (dispatch) => {
  dispatch(layerClassToggle(layerId, classId, isVisible));
};
