export const ADD_BOUNDARY = 'ADD_BOUNDARY';
export const ADD_MARKER = 'ADD_MARKER';
export const ADD_TEMP_POLYGON = 'ADD_TEMP_POLYGON';
export const APP_LOAD_CONFIG = 'APP_LOAD_CONFIG';
export const AUTHENTICATE_START = 'AUTHENTICATE_START';
export const AUTHENTICATE_SUCCESS = 'AUTHENTICATE_SUCCESS';
export const BOUNDARY_SELECT = 'BOUNDARY_SELECT';
export const CLEAR_MAP = 'CLEAR_MAP';
export const DRAWER_MENU_TOGGLE = 'DRAWER_MENU_TOGGLE';
export const ENABLE_DRAWING_MODE = 'ENABLE_DRAWING_MODE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';
export const LOAD_AREAS_START = 'LOAD_AREAS_START';
export const LOAD_AREAS_SUCCESS = 'LOAD_AREAS_SUCCESS';
export const LOAD_AREA_LAYERS_START = 'LOAD_AREA_LAYERS_START';
export const LOAD_AREA_LAYERS_SUCCESS = 'LOAD_AREA_LAYERS_SUCCESS';
export const LOAD_BOUNDARIES_START = 'LOAD_BOUNDARIES_START';
export const LOAD_BOUNDARIES_SUCCESS = 'LOAD_BOUNDARIES_SUCCESS';
export const LOAD_LAYERS_START = 'LOAD_LAYERS_START';
export const LOAD_LAYERS_SUCCESS = 'LOAD_LAYERS_SUCCESS';
export const LOAD_PRESELECTEDSCRIPTS = 'LOAD_PRESELECTEDSCRIPTS';
export const LOAD_SCRIPTS_INFO_START = 'LOAD_SCRIPTS_INFO_START';
export const LOAD_SCRIPTS_INFO_SUCCESS = 'LOAD_SCRIPTS_INFO_SUCCESS';
export const REMOVE_MARKER = 'REMOVE_MARKER';
export const SELECT_BOUNDARY = 'SELECT_BOUNDARY';
export const ADD_TEMP_BOUNDARY = 'ADD_TEMP_BOUNDARY';
export const SET_TEMP_BOUNDARIES_NAME = 'SET_TEMP_BOUNDARIES_NAME';
export const ADD_SECONDARY_BOUNDARY = 'ADD_SECONDARY_BOUNDARY';
export const REMOVE_SECONDARY_BOUNDARY = 'REMOVE_SECONDARY_BOUNDARY';
export const RESET_SECONDARY_BOUNDARIES = 'RESET_SECONDARY_BOUNDARIES';
export const SELECT_MONTHLY_AVERAGES = 'SELECT_MONTHLY_AVERAGES';
export const SELECT_MONTHLY_TIME_SERIES = 'SELECT_MONTHLY_TIME_SERIES';
export const SELECT_STATISTICS_DATE_RANGE = 'SELECT_STATISTICS_DATE_RANGE';
export const SELECT_TAB = 'SELECT_TAB';
export const SET_AVAILABLE_LANGUAGES = 'SET_AVAILABLE_LANGUAGES';
export const SET_SCRIPT_DATE_RANGE = 'SET_SCRIPT_DATE_RANGE';
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE';
export const SET_VIEWPORT = 'SET_VIEWPORT';
export const SET_LEFT_SPLIT_LAYER = 'SET_LEFT_SPLIT_LAYER';
export const SET_RIGHT_SPLIT_LAYER = 'SET_RIGHT_SPLIT_LAYER';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_GOOGLE_LOGIN_MODAL = 'SHOW_GOOGLE_LOGIN_MODAL';
export const SHOW_LOADING_SPINER = 'SHOW_LOADING_SPINER';
export const SHOW_MODAL_DISCLAIMER = 'SHOW_MODAL_DISCLAIMER';
export const SHOW_MODAL_LAYER_INFO = 'SHOW_MODAL_LAYER_INFO';
export const STATISTICS_DRAWER_OPEN = 'STATISTICS_DRAWER_OPEN';
export const TOGGLE_MAP_LAYER = 'TOGGLE_MAP_LAYER';
export const USER_ADD_BOUNDARY = 'USER_ADD_BOUNDARY';
export const USER_DELETE_BOUNDARY = 'USER_DELETE_BOUNDARY';
export const USER_LOAD_BOUNDARIES_SUCCESS = 'USER_LOAD_BOUNDARIES_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const ADD_BATCH_BOUNDARY = 'ADD_BATCH_BOUNDARY';
export const REMOVE_BATCH_BOUNDARY = 'REMOVE_BATCH_BOUNDARY';
export const RESET_BATCH_BOUNDARIES = 'RESET_BATCH_BOUNDARIES';
export const TOGGLE_LAYER_CLASS = 'TOGGLE_LAYER_CLASS';
